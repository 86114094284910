/* .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    width: 100%;
    height: 100% !important;
    overflow: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.5);
} */
.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    /* color: #227c9d; */
    /* background-color: #fef9ef;
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #227c9d; */
    /* border: 2px solid; */
    cursor: pointer;
    /* margin-bottom: 10px; */
}

.modal-close-btn svg{
    height:18px;
    width:18px;
    color: var(--card-text-color);
}
/* .modal-close-btn:hover { */
/* background-color: rgba(128, 128, 128, 0.5);  */
/* sets a transparent gray color with 50% opacity */
/* } */
/* .modal-close-btn:active { */
      /* border: 2px solid #227c9d;
      color: #227c9d;
      background-color:#fef9ef */
/* } */

/* .fa-opacity-5 {
    opacity: 0.5;
} */

/* .org-chart-margins {
} */

.default-img {
    width: 72.5px !important; 
    height: 72.5px !important;
}
.report-content {
    background-color: #f5f5f5; /* Light grey background for the whole report content */
    border-radius: 8px; /* Rounded corners for the report content */
    padding: 20px; /* Padding around the report content */
    margin-bottom: 20px; /* Space between sections */
}

.project-section {
    border-bottom: 1px solid #e1e1e1; /* Separator between projects */
    padding-bottom: 15px; /* Space at the bottom of each project section */
    margin-bottom: 15px; /* Space between project sections */
}

.project-section h2 {
    color: #333; /* Dark grey color for the project titles */
    margin-bottom: 10px; /* Space below the project titles */
}

.project-section ul {
    list-style-type: disc; /* Bullets for the commit details */
    margin-left: 20px; /* Indentation for the bullet list */
}

.project-section li {
    margin-bottom: 5px; /* Space between list items */
}