.report {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.8;
    color: #333;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Set a max-width for the report */
    margin: 40px auto; /* Centers the report with top and bottom margin */
    overflow: auto; /* Ensures the content doesn't overflow */
}

/* If you have a container class inside your report, ensure it doesn't restrict the report's width */
.report .container {
    width: 100%;
    padding: 0 100px 0 100px;
}
.report .container card.pd-0.custom-card {
    padding: 0 100px 0 100px;
}
.report h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.report h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.report h3 {
    font-size: 18px;
    margin-bottom: 10px;

}

.report p, .report li {
    font-size: 14px;
    margin-bottom: 10px;
}

.report ul {
    list-style: none;
    padding-left: 0;
    border-top: 1px solid #eee;
    padding-top:10px;
}

.report li {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

/* Additional styling can be added for links, buttons, or other elements as needed */
