.kanban-board {
    display: grid;
    grid-auto-flow: column; /* Arrange columns in a row */
    grid-auto-columns: minmax(250px, 1fr); /* Set a minimum width for columns */
    gap: 24px;
    padding: 16px;
    width: max-content; /* Ensures the board expands to fit all columns */
    overflow-x: auto;
    height: 100vh;
}

.kanban-column {
    background-color: #f4f4f4;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 100%;
    overflow-y: auto;
    min-width: 250px; /* Ensures columns have a minimum width */
}

.kanban-tasks {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.kanban-card {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px 16px;
    box-shadow: 0 1px 4px rgba(255, 255, 255, 0.1);
    border-left: 6px solid black;

    display: flex;
    flex-direction: column;
    max-width:100%;
    width: 100%;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease;
}
.small-new .kanban-card-assignee {
    display: none;
}

.kanban-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.small-new .kanban-card-front {



    max-height:220px;
}

.small-new.editing .kanban-card-front {
    max-height:100%;
}
.kanban-card .edit-btn {
    opacity: 0; /* Initially hidden */
    border: 1px solid #01b8ff;
    border-radius: 4px;
    transition: opacity 0.3s ease;
    padding: 4px 8px;
    background-color: #fff;
    color: #01b8ff;
    cursor: pointer;
}

.kanban-card:hover .edit-btn {
    opacity: 1; /* Show on hover */
}

.kanban-card .edit-btn:hover {
    background-color: #01b8ff;
    color: #fff;
}

.kanban-card.new {
    border-left-color: #000;
}

.kanban-card-inner {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.kanban-card-front,
.kanban-card-back {
    width: 100%;
    backface-visibility: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.kanban-card-front {
    z-index: 2;
    animation: fadeIn 0.6s forwards;
    position: relative;
}

.kanban-card-back {
    position: absolute;
    z-index: 1;
    opacity: 0;
    animation: fadeOut 0.3s forwards;
}

.kanban-card.editing .kanban-card-front {
    position: absolute;
    animation: fadeOut 0.3s forwards;
}

.kanban-card.editing .kanban-card-back {
    position: relative;
    opacity: 1;
    animation: fadeIn 0.6s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.kanban-card-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-wrap: break-word;
    hyphens: auto;
}

.kanban-card-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-wrap: break-word;
    hyphens: auto;
}


.team-status {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.team-status .kanban-tasks {
    flex-direction: row;
}
.small-new {
    margin: 4px;
    width:265px;
    border:1px solid #ccc !important;
    height:100%;
    max-height:220px;
}
.edit-btn {
    width:100%;
}
.small-new.editing {
    max-height:100%;
}
.small .kanban-card-description {
    display:none;

}

.small-new .kanban-card-description {
    max-width:320px;
    overflow:hidden !important;
}
.small .kanban-card-assignee {
    display:none;
}
.kanban-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kanban-card-tag {
    background: linear-gradient(135deg, #ececec, #f2f2f2); /* Nice warm gradient */
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 10px;
    color: #333;
    font-weight: bold;
}

.kanban-card-tag-small {
    background: linear-gradient(135deg, #817f7f, #958f8f); /* Nice warm gradient */
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 10px;
    color: #ffffff;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
    transition: transform 0.2s ease-in-out; /* Smooth transition for hover effect */
}

/* Optional hover effect for added interactivity */
.kanban-card-tag-small:hover {
    transform: scale(1.05); /* Slightly enlarges on hover */
}


.kanban-card-assignee {
    font-size: 14px;
    color: #333;
    margin-left: auto;
}

.kanban-card-input.date-picker {
    z-index:10002
}
.kanban-card-input,
.kanban-card-textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.kanban-card-textarea {
    resize: vertical;
}

.kanban-card button {
    margin-top: 8px;
}

.drag-card {
    height: auto;
}

/* Additional media query for smaller screens */
@media (max-width: 768px) {
    .kanban-board {
        overflow-x: scroll; /* Enables horizontal scrolling on smaller screens */
    }

    .kanban-column {
        min-width: 250px; /* Ensures columns retain their minimum width */
    }
}
.kanban-overlay {
    width: 100vw;
    height: 100vh;
    position:absolute;
    top:0;
    left:0;
    z-index:10000;
    background-color: rgba(0, 0, 0, 0.4); /* Slightly transparent black */
    backdrop-filter: blur(2px); /* Apply the blur effect */

}
.kanban-overlay-small {
    width: 100vw;
    height: 100vh;
    position:fixed;
    top:0;
    left:0;
    z-index:10000;
    background-color: rgba(0, 0, 0, 0.4); /* Slightly transparent black */
    backdrop-filter: blur(2px); /* Apply the blur effect */

}
.kanban-board .editing, .small-new.editing {
    z-index:10001;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.kanban-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly transparent black */
    backdrop-filter: blur(7x); /* Apply the blur effect */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}
.css-1mtsuo7 {
    z-index: 10002 !important;
}
.css-a78wou.Mui-selected {
    background-color: #a161ee !important;
    color: white !important;
}
.kanban-overlay.fade-in {
    opacity: 1;
    visibility: visible;
}

.kanban-overlay.fade-out {
    opacity: 0;
    visibility: hidden;
}


.kanban-card-edit-field label {
    margin-bottom:0;
    font-size:12px;
    font-weight:bold;
}

.kanban-card-edit-field input {
    margin-bottom:15px;
}
select.form-control {
    border: 1px solid #ddd !important
}
.kanban-column .kanban-card:last-child {
    margin-bottom: 0;
}
/* Additional CSS for customizing the date picker calendar */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #a161ee !important;
    color: white;
}

.react-datepicker__day:hover {
    background-color: #e9ecef;
}

.react-datepicker__header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.react-datepicker__month-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root, .MuiPopper-root {
    z-index: 20000 !important;
}
.MuiInputBase-input {
    border-color: #ddd !important;
    padding: 10px !important;
    font-size: 14px !important;
    margin-bottom: 0 !important;
}
.MuiButtonBase-root {
    margin-top: 0 !important;

}
.MuiFormControl-root {
    margin-bottom: 10px !important
}
.css-20bmp1-MuiSvgIcon-root {
    width: .85em !important;
}
.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #a161ee !important;
}

.kanban-column-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}


.kanban-card-actions {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

.delete-button {
    color: red;
    background: none;
    border: none;
    cursor: pointer;
}

.delete-button:hover {
    text-decoration: underline;
}

/* Skeleton Card Styles */
.skeleton-card {
    animation: pulse 1.5s infinite ease-in-out;
    height:121px;
}

.skeleton-card-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skeleton-line {
    background-color: #e5e5e5;
    height: 12px;
    border-radius: 4px;
}

@keyframes pulse {
    0% {
        background-color: #f0f0f0;
    }
    50% {
        background-color: #fefefe;
    }
    100% {
        background-color: #f0f0f0;
    }
}

.kanban-card-edit-field {
    display:flex;
    flex-direction: column;
}


.team-status {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
}

.team-status.fade-in {
    opacity: 1;
    max-height: 100%; /* Adjust based on the content size */
}

.team-status.fade-out {
    opacity: 0;
    max-height: 0;
}


/* Capacity Display Styles */
.capacity-display {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width:100%;
}

.capacity-display span {
    margin-right: 5px;
    font-weight: bold;
    font-size: 14px;
}

.capacity-bar {
    flex: 1;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    height: 10px;
    margin-right: 5px;
}

.capacity-fill {
    height: 100%;
    transition: width 0.3s ease, background-color 0.3s ease;
}

/* Edit Button Styles */
.edit-btn {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #1976d2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    align-self: flex-end;
}

.edit-btn:hover {
    background-color: #1565c0;
}
.kanban-card-front .MuiFormControl-root {
    display: none;
}
.small-new .kanban-card-front .MuiFormControl-root {
    margin-top:15px;
    display: flex;
}