.capacity-icon {
    width: 100%;
    height: 24px;
    margin-bottom:0;
    border-radius: 20px;
}

.capacity-svg {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.capacity-fill {
    transition: height 0.3s ease;  /* Smooth transition for the fill */
    border-radius: 20px;
}
