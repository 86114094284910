/* team.css */
.team-component {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white; /* White background for teammates */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    width: 120px;  /* Adjust the width to make the cards wider */
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);  /* Add a subtle shadow for a clean look */
    position: relative;
}

.team-card img.avatar {
    width: 60px;  /* Slightly larger avatar */
    height: 60px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.team-card span {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    border-radius: 8px;
}

.kanban-board.small .kanban-card-description {
    display: none;
}