.details-card {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
}

.section-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.subsection-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.section-description {
    font-size: 1rem;
    color: #666;
}

.code-block {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 20px;
}

.demo-block {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}

.demo-iframe {
    border: none;
}

.back-button {
    background-color: #6c757d;
    color: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #5a6268;
}
